import { clustersQuery } from "~/api/getClusters";
import { ClusterTable } from "../components/clusterList/ClusterTable";
import CreateButton from "../components/clusterList/CreateButton";
import styles from "./clusters.module.css";

import PageBanner from "~/components/PageBanner";
import type { QueryClient } from "@tanstack/react-query";
import { instance } from "~/auth";

export const clustersLoader = (queryClient: QueryClient) => async () => {
  if (!instance.getActiveAccount()) {
    return;
  }

  const query = clustersQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query).catch(() => undefined))
  );
};

export const Clusters = () => {
  return (
    <div className={styles.content}>
      <PageBanner
        title="Clusters"
        subTitle="Get an overview of your existing clusters, and create new ones"
        content={<CreateButton />}
        className={styles.banner}
      />

      <ClusterTable />
    </div>
  );
};
