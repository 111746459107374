import { Box, Breadcrumbs, Icon } from "@intility/bifrost-react";
import { HTTPError } from "ky";
import type { PropsWithChildren } from "react";
import { Link, useParams } from "react-router";
import { ErrorPage } from "~/pages/error/ErrorPage";
import type { UserDTO } from "../api/getUsers";
import { UserTeamsSection } from "../components/userDetails/UserTeamsSection";
import styles from "./UserDetails.module.css";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import { UserProfile } from "../components/access/UserProfile";
import { userQuery, useUser } from "../api/getUser";
import type { QueryClient } from "@tanstack/react-query";
import { instance } from "~/auth";

export const userLoader =
  (userId: string, queryClient: QueryClient) => async () => {
    if (!instance.getActiveAccount()) {
      return;
    }

    const query = userQuery(userId);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query).catch(() => undefined))
    );
  };

export const UserDetails = () => {
  const { userId } = useParams();

  const user = useUser(userId ?? "");

  if (user.isPending) {
    return (
      <Layout user={user.data}>
        <Icon.Spinner className={styles.loadingSpinner} size={42} />
      </Layout>
    );
  }

  if (user.error instanceof HTTPError && user.error.response.status === 403) {
    return <ErrorPage code={403} />;
  }

  if (user.isError) {
    return (
      <Layout user={user.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout user={user.data}>
      <UserProfile user={user.data} />
      <UserTeamsSection user={user.data} />
    </Layout>
  );
};

const Layout = ({
  children,
  user,
}: PropsWithChildren<{
  user: UserDTO | undefined;
}>) => {
  return (
    <div className={styles.container}>
      <Box shadow radius background="base-3" className={styles.box}>
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <Link to="/users">Users</Link>
          </Breadcrumbs.Item>

          {user && <Breadcrumbs.Item>{user.name}</Breadcrumbs.Item>}
        </Breadcrumbs>
      </Box>

      {children}
    </div>
  );
};
