import type { QueryClient } from "@tanstack/react-query";
import { teamsQuery } from "../../api/getTeams";
import { TeamsTable } from "./TeamsTable";
import { TeamsTableOptions } from "./TeamsTableOptions";
import { instance } from "~/auth";

export const teamsLoader = (queryClient: QueryClient) => async () => {
  if (!instance.getActiveAccount()) {
    return;
  }

  const query = teamsQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query).catch(() => undefined))
  );
};

export const TeamsTab = () => {
  return (
    <>
      <TeamsTableOptions />
      <TeamsTable />
    </>
  );
};
