import { Icon } from "@intility/bifrost-react";
import styles from "./createButton.module.css";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router";
import { useRole } from "~/hooks/useRole";

const CreateButton = () => {
  const role = useRole();

  if (!role.isAdmin) {
    return null;
  }

  return (
    <Link to="/clusters/new">
      <button className={styles.createButton}>
        <Icon icon={faCirclePlus} fontSize={30} />
        <h5 className={`bf-h5 ${styles.buttonText}`}>Create new cluster</h5>
      </button>
    </Link>
  );
};

export default CreateButton;
