import { createColumnHelper } from "@tanstack/react-table";
import type { TeamDTO } from "../../api/getTeam";
import { Link } from "react-router";
import { Button, Icon } from "@intility/bifrost-react";
import styles from "./TeamsTable.module.css";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { ModalContext } from "~/context/ModalContext";
import { useContext } from "react";
import { RoleBadge } from "../../../../components/RoleBadge";
import { DeleteTeamModal } from "./DeleteTeamModal";
import { Role } from "~/constants";
import { useRole } from "~/hooks/useRole";
import { sortByRole } from "~/utils/sortByRole";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";

const columnHelper = createColumnHelper<TeamDTO>();

export const teamColumns = [
  columnHelper.accessor("name", {
    header: "Team",
    id: "name",
    meta: {
      cellClassName: styles.teamNameCell,
    },
    cell: function Cell(cell) {
      const team = cell.row.original;

      return (
        <Link
          to={`/teams/${team.id}`}
          className="bf-title-link"
          onClick={(e) => e.stopPropagation()}
        >
          {team.name}
        </Link>
      );
    },
  }),
  columnHelper.accessor("description", {
    header: "Description",
    id: "description",
    meta: {
      cellClassName: styles.descriptionCell,
      fromSize: "small",
    },
  }),
  columnHelper.accessor((team) => getRoleFromRoles(team.roles), {
    header: "Your role",
    id: "role",
    sortingFn: (rowA, rowB) => {
      const roleA = getRoleFromRoles(rowA.original.roles);
      const roleB = getRoleFromRoles(rowB.original.roles);

      return sortByRole(roleA, roleB);
    },
    cell: function Cell(cell) {
      const team = cell.row.original;

      if (team.roles.length === 0) {
        // https://designsystem.morningstar.com/legacy/v/1.5.0/editorial/punctuation.html#em-dash
        return "—";
      }

      return <RoleBadge roles={team.roles} />;
    },
  }),
  columnHelper.display({
    header: "",
    id: "delete",
    meta: {
      cellClassName: styles.deleteButtonCell,
    },
    cell: function Cell(cell) {
      const team = cell.row.original;

      return <DeleteTeamButton team={team} />;
    },
  }),
];

const DeleteTeamButton = ({ team }: { team: TeamDTO }) => {
  const { handleModal } = useContext(ModalContext);

  const isTeamOwner = team.roles.includes(Role.Owner);

  const role = useRole();
  const isOrgAdmin = role.isAdmin;

  if (!isTeamOwner && !isOrgAdmin) {
    return null;
  }

  return (
    <Button
      variant="flat"
      state="neutral"
      pill
      noPadding
      aria-label="Delete team"
      onClick={(e) => {
        e.stopPropagation();

        handleModal({
          header: <>Delete team</>,
          content: <DeleteTeamModal team={team} />,
        });
      }}
    >
      <Icon icon={faTrashCan} />
    </Button>
  );
};
