import { Icon, Table } from "@intility/bifrost-react";
import styles from "./OrganizationRoleDescriptionTable.module.css";
import { faCheck, faX } from "@fortawesome/pro-regular-svg-icons";

export const OrganizationRoleDescriptionTable = () => {
  return (
    <Table vertical noBorder className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Member</Table.HeaderCell>
          <Table.HeaderCell>Admin</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.HeaderCell>Manage teams you own</Table.HeaderCell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Manage all teams</Table.HeaderCell>
          <Table.Cell>
            <CrossIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Access assigned clusters</Table.HeaderCell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Access all clusters</Table.HeaderCell>
          <Table.Cell>
            <CrossIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Create clusters</Table.HeaderCell>
          <Table.Cell>
            <CrossIcon />
          </Table.Cell>
          <Table.Cell>
            <CheckIcon />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const CheckIcon = () => (
  <Icon icon={faCheck} color="var(--bfc-base-c-success)" />
);

const CrossIcon = () => <Icon icon={faX} color="var(--bfc-base-c-alert)" />;
