import {
  faAtom,
  faBuilding,
  faHome,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Checkbox,
  Icon,
  Nav,
  Tooltip,
  useApplyTheme,
  type Theme,
} from "@intility/bifrost-react";
import { Link, NavLink, useLocation, useSearchParams } from "react-router";
import styles from "./navigation.module.css";
import { useAccount } from "@azure/msal-react";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faSignOutAlt, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useProfilePicture } from "~/api/getProfilePicture";
import cpmLogo from "~/assets/cpm-logo.svg";
import { logout } from "~/auth/instance";
import { cn } from "~/utils/clsx";
import { useMe } from "~/api/getMe";
import { getRoleFromRoles } from "~/utils/getRoleFromRoles";
import { Role } from "~/constants";
import type { PropsWithChildren } from "react";
import { env } from "~/utils/env";
import useLocalStorageState from "use-local-storage-state";
import { useQueryClient } from "@tanstack/react-query";

export default function Navigation({ children }: React.PropsWithChildren) {
  const [URLSearch] = useSearchParams();
  const queryClient = useQueryClient();
  const account = useAccount();
  const location = useLocation();
  const pathname = location.pathname;

  const me = useMe();
  const { data: profilePicture } = useProfilePicture();

  const [theme, setTheme] = useLocalStorageState<Theme>("bfTheme", {
    defaultValue: "system",
  });

  useApplyTheme(theme);

  const organizationName = me.data?.organizationName;
  const organizationRoles = me.data?.organizationRoles ?? [];
  const orgRole = getRoleFromRoles(organizationRoles);

  const accountFirstName = account?.name?.split(" ").at(0);
  const appVersion = env.appVersion;

  const handleLogout = () => {
    queryClient.clear();
    logout();
  };

  return (
    <Nav
      logo={
        <Link to="/home" className={styles.logo}>
          <Nav.Logo logo={cpmLogo}>
            <span className="from-small">Developer Platform</span>
          </Nav.Logo>
        </Link>
      }
      top={
        <>
          {orgRole && <OrgRoleNavItem role={orgRole} />}
          {organizationName && <OrgNameNavItem name={organizationName} />}

          <Nav.Group
            name={
              <img
                src={profilePicture}
                alt="profile"
                className="bf-nav-profile"
              />
            }
          >
            <Nav.Header className={styles.welcomeText}>
              Welcome, {accountFirstName}!
            </Nav.Header>

            <hr />

            <section>
              <Nav.Header>Color theme</Nav.Header>
              <Checkbox
                type="radio"
                name="theme"
                label="Light"
                checked={theme === "light"}
                onChange={() => setTheme("light")}
              />
              <Checkbox
                type="radio"
                name="theme"
                label="Dark"
                checked={theme === "dark"}
                onChange={() => setTheme("dark")}
              />
              <Checkbox
                type="radio"
                name="theme"
                label="System"
                checked={theme === "system"}
                onChange={() => setTheme("system")}
              />
            </section>

            <hr />

            {appVersion && (
              <>
                <Nav.Header className={styles.versionText}>
                  Version {appVersion}
                </Nav.Header>
                <hr />
              </>
            )}

            <button onClick={() => handleLogout()}>
              <Nav.Item hideCircle icon={faSignOutAlt}>
                Sign out
              </Nav.Item>
            </button>
          </Nav.Group>
        </>
      }
      side={
        <>
          <NavLink to="/home" end>
            <Nav.Item icon={faHome}>Home</Nav.Item>
          </NavLink>
          <NavLink to="/clusters" end>
            <Nav.Item icon={faAtom}>Clusters</Nav.Item>
          </NavLink>
          <NavLink
            to="/teams"
            className={cn({
              active:
                pathname.startsWith("/teams") || pathname.startsWith("/users"),
            })}
          >
            <Nav.Item icon={faUsers}>Teams</Nav.Item>
          </NavLink>
          <Nav.Group icon={faCircleInfo} name="Help center">
            <NavLink
              to="/helpcenter?tab=articles"
              className={({ isActive }) =>
                isActive && URLSearch.has("tab", "articles") ? "active" : ""
              }
            >
              <Nav.Item>Articles</Nav.Item>
            </NavLink>

            <NavLink
              to="/helpcenter?tab=support"
              className={({ isActive }) =>
                isActive && URLSearch.has("tab", "support") ? "active" : ""
              }
            >
              <Nav.Item>Support</Nav.Item>
            </NavLink>
          </Nav.Group>
        </>
      }
    >
      {children}
    </Nav>
  );
}

const OrgRoleNavItem = ({ role }: { role: Role }) => {
  const roleText = role === Role.Owner ? "Admin" : "Member";

  return (
    <>
      <MobileTooltip tooltipText={roleText}>
        <Icon icon={faUser} color="var(--bfc-base-c)" />
      </MobileTooltip>

      <Nav.Item className={cn("from-small", styles.centeredNavItem)}>
        <Icon icon={faUser} />
        <span className={cn(styles.navItemText)}>{roleText}</span>
      </Nav.Item>
    </>
  );
};

const OrgNameNavItem = ({ name }: { name: string }) => {
  return (
    <>
      <MobileTooltip tooltipText={name}>
        <Icon icon={faBuilding} color="var(--bfc-base-c)" />
      </MobileTooltip>

      <Nav.Item className={cn("from-small", styles.centeredNavItem)}>
        <Icon icon={faBuilding} />
        <span className={cn(styles.navItemText)}>{name}</span>
      </Nav.Item>
    </>
  );
};

const MobileTooltip = ({
  children,
  tooltipText,
}: PropsWithChildren<{ tooltipText: string }>) => {
  return (
    <Nav.Item className={cn(styles.centeredNavItem, "to-small")}>
      <Tooltip content={tooltipText} placement="bottom">
        <button>{children}</button>
      </Tooltip>
    </Nav.Item>
  );
};
