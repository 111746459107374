import { useSearchParams } from "react-router";
import { z } from "zod";
import { UsersGrid } from "./UsersGrid";
import { UsersOptions } from "./UsersOptions";
import { UsersTable } from "./UsersTable";
import type { QueryClient } from "@tanstack/react-query";
import { usersQuery } from "../../api/getUsers";
import { instance } from "~/auth";

export const usersLoader = (queryClient: QueryClient) => async () => {
  if (!instance.getActiveAccount()) {
    return;
  }

  const query = usersQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query).catch(() => undefined))
  );
};

export const UsersTab = () => {
  const [searchParams] = useSearchParams();

  const displayModeParam = searchParams.get("display");

  const displayMode = z
    .enum(["list", "tile"])
    .catch("list")
    .parse(displayModeParam);

  return (
    <>
      <UsersOptions />
      {displayMode === "list" ? <UsersTable /> : <UsersGrid />}
    </>
  );
};
