import { Box, Breadcrumbs, Icon } from "@intility/bifrost-react";
import { HTTPError } from "ky";
import type { PropsWithChildren } from "react";
import { Link, useParams } from "react-router";
import { ErrorPage } from "~/pages/error/ErrorPage";
import { type TeamDTO, teamQuery, useTeam } from "../api/getTeam";
import { TeamMembersSection } from "../components/teamDetails/TeamMembersSection";
import styles from "./TeamDetails.module.css";
import { TeamProfile } from "../components/teamDetails/TeamProfile";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import type { QueryClient } from "@tanstack/react-query";
import { instance } from "~/auth";

export const teamLoader =
  (teamId: string, queryClient: QueryClient) => async () => {
    if (!instance.getActiveAccount()) {
      return;
    }

    const query = teamQuery(teamId);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query).catch(() => undefined))
    );
  };

export const TeamDetails = () => {
  const { teamId } = useParams();

  const team = useTeam(teamId ?? "");

  if (team.isPending) {
    return (
      <Layout team={team.data}>
        <Icon.Spinner className={styles.loadingSpinner} size={42} />
      </Layout>
    );
  }

  if (team.error instanceof HTTPError && team.error.response.status === 403) {
    return <ErrorPage code={403} />;
  }

  if (team.isError) {
    return (
      <Layout team={team.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout team={team.data}>
      <TeamProfile team={team.data} />
      <TeamMembersSection team={team.data} />
    </Layout>
  );
};

const Layout = ({
  children,
  team,
}: PropsWithChildren<{ team: TeamDTO | undefined }>) => {
  return (
    <div className={styles.container}>
      <Box shadow radius background="base-3" className={styles.box}>
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <Link to="/teams">Teams</Link>
          </Breadcrumbs.Item>
          {team && <Breadcrumbs.Item>{team.name}</Breadcrumbs.Item>}
        </Breadcrumbs>
      </Box>

      {children}
    </div>
  );
};
