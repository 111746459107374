import { Grid, Icon } from "@intility/bifrost-react";
import type { PropsWithChildren } from "react";
import { useParams } from "react-router";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";
import type { Cluster as TCluster } from "~/types";
import { clusterQuery, useCluster } from "../api/getCluster";
import { ClusterAdministrationCards } from "../components/clusterDetails/ClusterAdministrationCards";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { ClusterInfoCard } from "../components/clusterDetails/ClusterInfoCard";
import { GuideCard } from "../components/clusterDetails/GuideCard";
import styles from "./Cluster.module.css";
import { HTTPError } from "ky";
import { ErrorPage } from "~/pages/error/ErrorPage";
import type { QueryClient } from "@tanstack/react-query";
import { instance } from "~/auth";

export const clusterLoader =
  (clusterId: string, queryClient: QueryClient) => async () => {
    if (!instance.getActiveAccount()) {
      return;
    }

    const query = clusterQuery(clusterId);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query).catch(() => undefined))
    );
  };

export const Cluster = () => {
  const { clusterId } = useParams();

  const cluster = useCluster(clusterId ?? "");

  if (cluster.isPending) {
    return (
      <Layout cluster={cluster.data}>
        <Icon.Spinner className={styles.loadingSpinner} size={42} />
      </Layout>
    );
  }

  if (
    cluster.error instanceof HTTPError &&
    cluster.error.response.status === 403
  ) {
    return <ErrorPage code={403} />;
  }

  if (cluster.isError) {
    return (
      <Layout cluster={cluster.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster.data}>
      <ClusterInfoCard cluster={cluster.data} />

      <Grid small={3}>
        <ClusterAdministrationCards cluster={cluster.data} />
      </Grid>

      <GuideCard />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
}: PropsWithChildren<{ cluster: TCluster | undefined }>) => {
  return (
    <div className={styles.content}>
      <ClusterBreadcrumbs cluster={cluster} />

      {children}
    </div>
  );
};
